<template>
  <div class="mx-input-checkbox">
    <input :id="id" v-model="model" v-bind="$attrs" type="checkbox" class="mx-input-checkbox__input" />

    <label v-if="label" :for="id" data-testid="mx-input-checkbox-label" class="mx-input-checkbox__label">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'MXInputCheckbox',
  props: {
    id: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss">
.mx-input-checkbox {
  display: grid;
  grid-template-columns: 20px auto;
  gap: 4px;
  align-items: center;
}

.mx-input-checkbox__input {
  appearance: none;
  background-color: $white;
  width: 14px;
  height: 14px;
  border: 1px solid #bababa;
  border-radius: 2px;
  display: grid;
  place-content: center;

  &::before {
    content: '';
    width: 7px;
    height: 6px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    transform-origin: bottom left;
    box-shadow: inset 1em 1em $white;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  &:checked {
    background-color: $purple-dark;
  }

  &:checked::before {
    transform: scale(1);
  }

  &:focus {
    box-shadow: 0 0 0 1px $white, 0 0 0 3px #7427f1;
  }
}

.mx-input-checkbox__label {
  font-size: 0.875rem;
  color: $grey;
  margin: 0;
}
</style>
