<template>
  <label
    class="order-bump-preview-item"
    data-testid="item-header"
    :class="{ 'order-bump-preview-item--selected': isSelected }"
  >
    <div class="order-bump-preview-item__checkbox-header">
      <MXInputCheckbox
        :id="`order-bump-${id}`"
        v-model="isSelected"
        :label="callToActionText"
        class="order-bump-preview-item__checkbox"
      />
    </div>

    <div class="order-bump-preview-item__content-wrapper">
      <div class="order-bump-preview-item__content-price-header">
        <img
          v-if="image"
          :src="image"
          format="webp"
          alt="Capa do produto order bump"
          width="104"
          height="58"
          placeholder
          class="order-bump-preview-item__content-cover"
        />

        <div class="order-bump-preview-item__content">
          <h3 class="order-bump-preview-item__title">
            {{ title }}
          </h3>

          <div class="order-bump-preview-item__price-wrapper">
            <p class="order-bump-preview-item__anchor-price-text">
              <template v-if="discountPercentage">
                Preço de
                <span
                  class="order-bump-preview-item__anchor-price-text order-bump-preview-item__anchor-price-text--line-through"
                >
                  {{ convertCentsToBRL(basePrice) }}
                </span>
                por
              </template>
              <span class="order-bump-preview-item__price-text order-bump-preview-item__price-text--highlight">
                {{ convertCentsToBRL(customPrice * 100) }}
              </span>
            </p>

            <div v-if="discountPercentage" class="order-bump-preview-item__price-discount-badge">
              <hs-icon variant="regular" icon="arrow-down" />
              {{ discountPercentage }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="descriptionText" class="order-bump-preview-item__description">
        {{ descriptionText }}
      </div>
    </div>
  </label>
</template>

<script>
import MXInputCheckbox from '@/shared/components/MXInputCheckbox.vue';

export default {
  name: 'OrderBumpPreviewItem',
  components: {
    MXInputCheckbox,
  },
  props: {
    id: {
      required: true,
    },
    basePrice: {
      type: Number,
      required: true,
    },
    customPrice: {
      type: Number,
      required: true,
    },
    image: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    callToAction: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  data: () => ({ isSelected: false }),
  computed: {
    callToActionText() {
      return this.callToAction || this.$t('sparkmembers.orderbump.preview-item.label');
    },
    descriptionText() {
      return this.description;
    },
    discountPercentage() {
      const percentage = ((this.basePrice - this.customPrice * 100) / this.basePrice) * 100;

      if (percentage < 1) return null;
      if (percentage > 99 && percentage < 100) return `${percentage.toFixed(2)}%`;

      return `${percentage.toFixed(0)}%`;
    },
  },
  methods: {
    convertCentsToBRL(priceInCents, currencyStyle = 'currency') {
      const convertedPrice = priceInCents / 100;

      return convertedPrice.toLocaleString('pt-BR', {
        style: currencyStyle,
        currency: 'BRL',
        minimumFractionDigits: 2,
      });
    },
  },
};
</script>

<style lang="scss">
.order-bump-preview-item {
  display: grid;
  grid-template-columns: 1fr;
  background-color: $white;
  border-radius: 4px;
  border: 2px dashed $purple-dark;
  margin: 0;
  cursor: pointer;
}

.order-bump-preview-item--selected {
  border: 2px solid $purple-dark;
}

.order-bump-preview-item__checkbox-header {
  display: flex;
  padding: 12px;
  background-color: $grey-16;
}

.order-bump-preview-item__checkbox {
  align-self: start;
}

.order-bump-preview-item__content-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 12px;
}

.order-bump-preview-item__content-price-header {
  display: flex;
  column-gap: 12px;
  align-items: center;
}

.order-bump-preview-item__content-cover {
  display: none;

  @media (min-width: $screen-md) {
    display: block;
    border-radius: 2px;
    height: 58px;
    width: 104px;
    object-fit: cover;
  }
}

.order-bump-preview-item__content {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.order-bump-preview-item__title {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: $grey;
  font-weight: 700;
}

.order-bump-preview-item__price-wrapper {
  display: flex;
  column-gap: 4px;
  align-items: center;
}

.order-bump-preview-item__anchor-price-text {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 600;
  color: $grey;
  margin: 0;
}

.order-bump-preview-item__anchor-price-text--line-through {
  color: $color-neutral-600;
  text-decoration: line-through;
}

.order-bump-preview-item__price-text {
  font-size: 0.875rem;
  line-height: 1.313rem;
  font-weight: 600;
  color: $grey;
}

.order-bump-preview-item__price-text--highlight {
  color: #348535;
  font-weight: 700;
}

.order-bump-preview-item__price-discount-badge {
  padding: 5px 8px;
  border-radius: 4px;
  background-color: #348535;
  color: $white;
  font-size: 0.75rem;
  border: none;
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.order-bump-preview-item__price-discount-icon {
  font-size: 1rem;
}

.order-bump-preview-item__description {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: $grey-32;
}
</style>
